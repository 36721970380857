<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="480"
    scrollable
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addTransportManager)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text ml-1"
              >Add Transport Manager</span
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close" color="prmary">
              <i class="material-icons">close</i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="transport.name"
                    class="ft font-weight-medium font-size-md"
                    label="Laison's Full Name"
                    :error-messages="errors[0]"
                    hint="Name of the transport manager"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|email"
                >
                  <v-text-field
                    outlined
                    v-model="transport.email"
                    class="ft font-weight-medium font-size-md"
                    label="Email address"
                    :error-messages="errors[0]"
                    hint="Email address of transport manager"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    v-model="transport.username"
                    class="ft font-weight-medium font-size-md"
                    label="Username"
                    :error-messages="errors[0]"
                    hint="Username of transport manager"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|numeric|min:10|max:13"
                >
                  <v-text-field
                    outlined
                    v-model="transport.mobile"
                    class="ft font-weight-medium font-size-md"
                    label="Contact Number"
                    :error-messages="errors[0]"
                    hint="Contact of transport manager"
                  />
                </ValidationProvider>
              </v-row>
              <p class="ft font-weight-medium font-size-sm red--text">
                A temporary email will be sent to your email address.
              </p>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, max, min, numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("min", {
  ...min,
  message: "Invalid phone number provided"
});
extend("max", {
  ...max,
  message: "Invalid phone number provided"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
extend("email", {
  ...email,
  message: "Invalid email address provided"
});
export default {
  name: "AddDriverAndAssignCar",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver
  },
  props: {
    cars: {
      type: Array,
      default: () => []
    },
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogState: {
      newCar: false
    },
    transport: {
      name: "",
      email: "",
      username: "",
      mobile: "",
      userType: "transport",
      isAdminUser: true
    },
    reset: {
      name: "",
      email: "",
      username: "",
      mobile: "",
      userType: "transport"
    }
  }),
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  watch: {
    resetFormState(value) {
      if (value) {
        this.transport = { ...this.reset };
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    }
  },
  methods: {
    addTransportManager() {
      this.$emit("addManager", this.transport);
    },
    close() {
      this.$emit("actions", "details");
    }
  }
};
</script>
