var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"480","scrollable":"","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addTransportManager)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold blue-grey--text ml-1"},[_vm._v("Add Transport Manager")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"prmary"},on:{"click":_vm.close}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])],1),_c('v-card-text',[_c('v-col',[_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","autofocus":"","label":"Laison's Full Name","error-messages":errors[0],"hint":"Name of the transport manager"},model:{value:(_vm.transport.name),callback:function ($$v) {_vm.$set(_vm.transport, "name", $$v)},expression:"transport.name"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","label":"Email address","error-messages":errors[0],"hint":"Email address of transport manager"},model:{value:(_vm.transport.email),callback:function ($$v) {_vm.$set(_vm.transport, "email", $$v)},expression:"transport.email"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","label":"Username","error-messages":errors[0],"hint":"Username of transport manager"},model:{value:(_vm.transport.username),callback:function ($$v) {_vm.$set(_vm.transport, "username", $$v)},expression:"transport.username"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md",attrs:{"outlined":"","label":"Contact Number","error-messages":errors[0],"hint":"Contact of transport manager"},model:{value:(_vm.transport.mobile),callback:function ($$v) {_vm.$set(_vm.transport, "mobile", $$v)},expression:"transport.mobile"}})]}}],null,true)})],1),_c('p',{staticClass:"ft font-weight-medium font-size-sm red--text"},[_vm._v(" A temporary email will be sent to your email address. ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ft text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft white--text text-capitalize",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }